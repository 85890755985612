module.exports = [{
      plugin: require('../plugins/gatsby-plugin-gclid-capture/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"devKey":"8hGoP2hrHoQCWPlS9GuckmSOmdQf7X1Y","prodKey":"N8K1UgqbJbzB81X02X5jUrSH8anNXD56","host":"https://cdn.segment.com","trackPage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.parsleyhealth.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
