/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const isThirdPartyDisabled =
  process.env.GATSBY_DISABLE_THIRD_PARTY_INTEGRATIONS === 'true';

if (!isThirdPartyDisabled) {
  module.exports = require('./gatsby/dist/browser');
}
